@import 'src/base';

.sites-dashboard {
    width: 100%;
    background-color: $white;
    margin: auto;
    border-radius: 5px;
    .list-header {
        padding: 0 24px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .list-title {
        font-weight: bold;
    }
    .list-item {
        padding: 0;
        height: 100%;
    }
    .list-info {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .sites-dashboard {
        width: 100%;
        margin: 0;
    }
}
