@import 'src/base';

.site-policy-history {
    .ant-timeline.ant-timeline-label .ant-timeline-item-label {
        width: calc(12% - 12px);
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
        left: calc(12% - 4px);
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-tail {
        left: 12%;
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-head {
        left: 12%;
    }
}
