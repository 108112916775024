@import 'src/base';

.massive-rfid-cards{
    background-color: $white;
    width: 100%;
    margin: auto;
    padding: 2rem;
    .massive-rfid-cards-form-search{
        border-color: #d4d4d4 !important;
        box-shadow: none !important;
    }
    .ant-input-affix-wrapper-status-error{
        border-color: #d4d4d4 !important;
        box-shadow: none !important;
    }
    .massive-rfid-cards-form-search:focus{
        box-shadow: 0 0 0 2px rgba(177, 177, 177, 0.2) !important;
    }
    .ant-input-affix-wrapper-status-error:focus{
        box-shadow: 0 0 0 2px rgba(177, 177, 177, 0.2) !important;
    }
    .massive-rfid-cards-form-cards-container{
        width: 100%;
        border: 1px solid rgba(140, 140, 140, 0.35);
        .massive-rfid-cards-form-search-admin{
            display: flex;
            flex-direction: row;
            gap: 1rem;
            padding: 1rem;
        }
        .scrollableDiv{
            height: 400px;
            overflow: auto;
            padding: 0 16px;
        }
    }
}