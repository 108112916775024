@import 'src/base';

.input-search {
    padding: 4px 11px;
    border: none;
    outline: none;
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(38, 38, 38, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    display: inline-flex;
}
.input-search:hover {
    border-color: #9e87c9;
    border-right-width: 1px;
}
.input-search:focus {
    border-color: #9e87c9;
    box-shadow: 0 0 0 2px rgba(124, 95, 188, 0.2);
    border-right-width: 1px;
    outline: 0;
}
