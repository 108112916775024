@import 'src/base';

.create-key {
    .ant-modal-header {
        padding: 12px 24px 0px 24px;
    }
    .duration-radio-group{
        display: flex;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
        // margin-bottom: 1rem;
        .ant-radio-button-wrapper{
            border-radius: 8px;
            border-left-width: 1px;
            // padding: .3rem 1rem;
            height: fit-content;
            width: fit-content;
        }
        label.ant-radio-button-wrapper::before{
            background-color: red;
            display: none;
        }
    }
}
