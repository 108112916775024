@import 'src/base';

.app-bar {
    background-color: $primary-color;
    color: $white;
    padding: 0 1rem;
    .header-img {
        width: 150px;
    }
}
