@import 'src/base';

.passcodes {
    width: 90%;
    margin: auto;
    border-radius: 5px;
    display: grid;

}

@media screen and (max-width: $breakpoint-lg) {
    .access-reports-dashboard {
        width: 100%;
        margin: 0;
        padding: 1rem;

     }
}