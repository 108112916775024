@import 'src/base';

.operation-import-modal {
    .operation-import-modal-card-item{
        padding: 5px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        min-height: 180px;

        .ant-card {
            background-color: #f9f9f9;
            .ant-card-head {
                padding: 0 1rem;
                background-color: #f6f6f6;
                min-height: 30px;
                font-weight: 600;
                .ant-card-head-title{
                    padding: 5px;
                }
            }
            .ant-card-body {
                padding: .5rem 1rem;
            }
            .ant-menu{
                    border: none;
                background-color: transparent;
                .ant-menu-item{
                    margin: 0;
                    padding: 0 .5rem;
                    height: 35px;
                    line-height: 35px;
                }
            }
        }
    }
    .file-select {
        position: relative;
        display: inline-block;
        border-radius: 3px;

        width: 100%;
        .file-select-content {

            color: black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.02);
            border-radius: 3px;
            border: 1px dashed #d9d9d9;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            transition: 0.3s;
        }
    }
    .file-select:hover {
        .file-select-content {
            border: 1px dashed #2093ff;
        }
    }
    .file-select input[type="file"] {
        opacity: 0;
        width: -webkit-fill-available;
        height: 100px;
        display: inline-block;
        cursor: pointer;
    }
}