@import 'src/base';

.reset-password {
  padding: 50px;
  .reset-form {
    @extend .rounded;
    background-color: lighten($primary-color, 30%);
    padding: 10px 25px;
    max-width: 400px;
  }
}
