@import 'src/base';

.admins-dashboard {
    width: 90%;
    margin: auto;
    border-radius: 5px;
    display: grid;
    .filter-tags{
        display: flex;
        flex-wrap: wrap;
        // width: fit-content;
        // white-space: nowrap;
        min-width: 100%;
        // background-color: #f2690055;
        // overflow-x: auto;
        overflow-y: auto;
        max-height: 60px;
        
    }
    .filter-tags::-webkit-scrollbar{
        width: 7px;
        height: 7px;
    }
    .filter-tags::-webkit-scrollbar-track{
        width: 5px;
        height: 5px;
        background: rgb(208, 208, 208);
        border-radius: 20px;
    }
    .filter-tags::-webkit-scrollbar-thumb{
        background: rgb(168, 168, 168);
        border-radius: 20px;
        border: 2px solid rgb(178, 178, 178);
        
    }
    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .list-item {
        padding: 0;
        height: 100%;
    }
    .list-info {
        height: 100%;
        width: 100%;
    }
    .image {
        width: 350px;
        height: 175px;
    }
    .list {
        background-color: $white;
        padding: 0;
    }
    .ant-list-header {
        background-color: #fafafa;
    }
    .ant-list-bordered .ant-list-pagination {
        margin: 0;
    }
}
@media screen and (max-width: $breakpoint-lg) {
    .admins-dashboard {
        width: 100%;
        margin: 0;
        padding: 1rem;

        .list-info {
            padding: 1rem;
        }
    }
}
