@import 'src/base';

.delete-site-card-progress-modal {
    .delete-site-card-progress-modal-footer{
        display: flex;
        justify-content: space-between;
        .delete-site-card-progress-modal-footer-retry{
            display: flex;
            align-items: center;
            gap: 1rem;
        }
    } 
    .delete-site-card-progress-modal-scrollable-result-count{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1.5rem;
    }
    .delete-site-card-progress-modal-scrollable{ 
        max-height: 400px;
        overflow-y: scroll;
    }
}