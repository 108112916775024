@import 'src/base';

.create-site {
    width: 100%;
    .site-page-header {
        border: 1px solid rgb(235, 237, 240);
        width: 100%;
    }
    .form {
        width: 100%;
        padding: 1rem 1rem 0 1rem;
    }
}
