@import 'src/base';

.access-reports-dashboard {
    width: 90%;
    margin: auto;
    border-radius: 5px;
    display: grid;
    .filter-tags{
        display: flex;
        flex-wrap: wrap;
        min-width: 100%;
        overflow-y: auto;
        max-height: 60px;
        
    }
    // .list-header {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    // }

    // .list-item {
    //     padding: 0;
    //     height: 100%;
    // }
    // .list-info {
    //     height: 100%;
    //     width: 100%;
    // }
    // .image {
    //     width: 350px;
    //     height: 175px;
    // }
    // .list {
    //     background-color: $white;
    //     padding: 0;
    // }
    // .ant-list-header {
    //     background-color: #fafafa;
    // }
    // .ant-list-bordered .ant-list-pagination {
    //     margin: 0;
    // }
}
@media screen and (max-width: $breakpoint-lg) {
    .access-reports-dashboard {
        width: 100%;
        margin: 0;
        padding: 1rem;

        .list-info {
            padding: 1rem;
        }
    }
}
