@import 'src/base';

.view-admins-modal {
    .ant-modal-header {
        padding: 12px 24px 0px 24px;
    }
    #scrollableDiv{
        ::-webkit-scrollbar{
            width: 7px;
            height: 7px;
        }
        ::-webkit-scrollbar-track{
            width: 5px;
            height: 5px;
            background: rgb(208, 208, 208);
            border-radius: 20px;
        }
        ::-webkit-scrollbar-thumb{
            background: rgb(168, 168, 168);
            border-radius: 20px;
            border: 2px solid rgb(178, 178, 178);
            
        }
    }
}
