@import 'src/base';

.filter-modal-access-reports {
    .ant-modal-header {
        padding: 12px 24px 0px 24px;
    }
    .ant-modal-content{
        .ant-modal-footer{
            display: flex;
        }
    } 
}