@import 'src/base';

.download-policies-list::-webkit-scrollbar{
    width: 7px;
    height: 7px;
}
.download-policies-list::-webkit-scrollbar-track{
    background: rgb(184,184,184);
    width: 5px;
    height: 5px;
    margin: 2px;
    border-radius: 20px;
}
.download-policies-list::-webkit-scrollbar-thumb{
    background-color: rgb(116,116,116);
    border: 2px solid rgb(116,116,116);
    border-radius: 20px;

}
.site-policies{
    width: 90%;
    margin: auto;
    border-radius: 5px;
    display: grid;
    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .list {
        background-color: $white;
        padding: 0;
        align-content: center;
    }
    .ant-list-header {
        background-color: #fafafa;
    }
    .ant-list-bordered .ant-list-pagination {
        margin: 0;
    }
    .actions {
        .cascader{
            width: 50%,
        }
        .mid{
            width: 50%,
        }
    }
    .list{
        align-items: center;
        align-content: center;

    }
}

